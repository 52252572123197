import GatsbyLink from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import OutboundLink from '../OutboundLink'
import * as style from './style.module.scss'

function Link({ link, ...props }) {
  if (link.startsWith('http')) {
    return <OutboundLink href={link}>{props.children}</OutboundLink>
  }
  return <GatsbyLink to={link} {...props} />
}

export default function Card(props) {
  return (
    <div className={style.card}>
      <Link
        link={typeof props.link === 'string' ? props.link : props.link.pathname}
        state={props.link && props.link.state ? props.link.state : undefined}
      >
        <div className={style.featuredMedia}>
          {props.image.childImageSharp ? (
            <GatsbyImage image={getImage(props.image)} />
          ) : (
            <img alt="" src={props.image} />
          )}
          <div className={style.mediaOverlay}>
            <div className={style.innerPadding}>
              <div className={style.flexColumn}>
                <div className={style.sublineOverlay}>{props.date}</div>
                <div className={style.sublineOverlay}>{props.subline}</div>
                <h2 className={style.titleOverlay}>{props.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
