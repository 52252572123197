import GatsbyLink from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import OutboundLink from '../OutboundLink'
import * as style from './style.module.scss'

function Link({ link, ...props }) {
  return <GatsbyLink to={link} {...props} />
}

export default function CardBlogPost(props) {
  return (
    <Link
      className={style.cardBlogPost}
      link={typeof props.link === 'string' ? props.link : props.link.pathname}
      state={props.link && props.link.state ? props.link.state : undefined}
    >
      <div className={style.media}>
        {props.image.childImageSharp ? (
          <GatsbyImage image={getImage(props.image)} />
        ) : (
          <img alt="" src={props.image} />
        )}
      </div>
      <div className={style.copy}>
        <h5 className={style.title}>{props.title}</h5>
        <p className={style.subline}>{props.subline}</p>
      </div>
    </Link >
  )
}
