import PageBlogPosts from '../components/PageBlogPosts'
import { graphql } from 'gatsby'

export default PageBlogPosts

export const query = graphql`
  query PageBlogPosts {
    site {
      siteMetadata {
        title
        description
      }
    }
    blogPosts: allMdx(
      filter: { fields: { sourceName: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            id
            postTitle
          }
        }
      }
    }
    articles: allMdx(filter: { fields: { sourceName: { eq: "blog-posts" } } }) {
      edges {
        node {
          frontmatter {
            id
            tags_for_display
            postTitle
            date
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
