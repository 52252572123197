import * as React from 'react'
import * as style from './style.module.scss'

export default function StickyBar(props) {
  return (
    <div className={style.stickyBar}>
      <div {...props} />
    </div>
  )
}
